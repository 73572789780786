
import * as d3 from "../d3";

import { WIDTH, HEIGHT, EMPTY, LINE, STROKE_WIDTH, STROKE, CIRCLE, FILL, CX, CY, R, X1, Y1, X2, Y2, PATH, D } from "./constants";
import { DataPoint } from "../interfaces";
import { VarianceIcon } from "@zebrabi/constants";

const SVG = "svg";
const ICON_CIRCLE_ARROW_POSITIVE = "M20 10c0-5.522-4.476-10-10-10C4.479 0 0 4.479 0 10c0 5.524 4.478 10 10 10 5.524 0 10-4.476 10-10Zm-14.53.28a.75.75 0 0 1-.073-.976l.073-.084 4-4a.75.75 0 0 1 .977-.073l.085.072 4 4.002a.75.75 0 0 1-.977 1.133l-.084-.073-2.72-2.721v6.691a.75.75 0 0 1-.649.743l-.102.007a.75.75 0 0 1-.743-.648l-.007-.102v-6.69l-2.72 2.72a.75.75 0 0 1-.976.072l-.084-.072Z";
const ICON_CIRCLE_ARROW_NEGATIVE = "M-.001 9.999c0 5.523 4.477 10 10 10s10-4.477 10-10-4.477-10-10-10-10 4.477-10 10Zm14.53-.28a.75.75 0 0 1 .073.976l-.072.085-4.001 4a.75.75 0 0 1-.977.073l-.084-.073-4-4.001a.75.75 0 0 1 .977-1.133l.084.072 2.72 2.722V5.75a.75.75 0 0 1 .649-.744L9.999 5a.75.75 0 0 1 .743.648l.007.102v6.69l2.72-2.72a.75.75 0 0 1 .977-.073l.084.073Z";
const ICON_TRIANGLE_POSITIVE = "M10.0829 2L18 18H2L10.0829 2Z";
const ICON_TRIANGLE_NEGATIVE = "M9.9171 18L2 2L18 2L9.9171 18Z";

export function createSvgElement(container: HTMLElement, classed: string): d3.Selection<SVGElement, any, any, any> {
    const svg = d3.select(container)
        .append(SVG)
        .attr(WIDTH, 0)
        .attr(HEIGHT, 0);
    if (classed !== EMPTY) {
        svg.classed(classed, true);
    }
    return svg;
}

export function drawLine(container: d3.Selection<SVGElement, any, any, any>, x1: number, x2: number, y1: number, y2: number, width: number, color: string, classed: string, dp?: DataPoint) {
    return container.append(LINE).data([dp]).classed(classed, true)
        .attr(X1, x1)
        .attr(Y1, y1)
        .attr(X2, x2)
        .attr(Y2, y2)
        .attr(STROKE_WIDTH, width)
        .attr(STROKE, color);
}

export function drawVarianceIcon(container: d3.Selection<SVGElement, any, any, any>, varianceIcon: VarianceIcon, color: string, isNegative: boolean) {
    if (varianceIcon === VarianceIcon.Circle) {
        return (container.append(CIRCLE)
            .attr(CX, 10)
            .attr(CY, 10)
            .attr(R, 8))
            .attr(FILL, color);
    }
    return container.append(PATH)
        .attr(D, () => {
            if (varianceIcon === VarianceIcon.Triangle) {
                return isNegative ? ICON_TRIANGLE_NEGATIVE : ICON_TRIANGLE_POSITIVE;
            } else {
                return isNegative ? ICON_CIRCLE_ARROW_NEGATIVE : ICON_CIRCLE_ARROW_POSITIVE;
            }
        })
        .attr(FILL, color);
}

export function getBoundingBoxHtml(element: HTMLElement): DOMRect {
    return element.getBoundingClientRect();
}
