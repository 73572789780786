import { DataView } from "@zebrabi/table-data";

import {
    AC, PY, PL, FC, VALUE_HEADER, ABSOLUTE_DIFFERENCE_HEADER, RELATIVE_DIFFERENCE_HEADER, SECOND_ABSOLUTE_DIFFERENCE_HEADER, SECOND_RELATIVE_DIFFERENCE_HEADER,
    REFERENCE_HEADER, SECOND_REFERENCE_HEADER, DELTA_AC, DELTA_PY, DELTA_PL, DELTA_FC, DELTA_AC_PERCENT, DELTA_FC_PERCENT, DELTA_PL_PERCENT, DELTA_PY_PERCENT, SECOND_VALUE_HEADER
} from "./library/constants";
import { ChartSettings } from "./settings/chartSettings";
//import { Scenario } from "./library/constants";
import { MeasureRoles } from "@zebrabi/data-helpers/fieldAssignment";
import { getLastNonNullValueIndex } from "./viewModel/viewModels";
import { Scenario } from "@zebrabi/constants";
import { ScenarioOptions } from "@zebrabi/charts/dist/lib/typings";

export function hasRoleInDataView(dataView: DataView, role: string) {
    if (dataView.metadata.columns.some(vs => vs.roles[role])) {
        return true;
    }
    return false;
}

function getScenarioIndex(dataView: DataView, role: string): number {
    return dataView.metadata.columns.find(c => c.roles[role] === true)?.index;
}

function getScenarioRoleString(scenario: Scenario): string {
    switch (scenario) {
        case Scenario.Actual: return MeasureRoles.Values;
        case Scenario.PreviousYear: return MeasureRoles.PreviousYear;
        case Scenario.Plan: return MeasureRoles.Plan;
        case Scenario.Forecast: return MeasureRoles.Forecast;
    }
}

// eslint-disable-next-line max-lines-per-function
export function getScenarioOptionsExcel(dataView: DataView) {
    const options: ScenarioOptions = getEmptyScenarioOptions();
    if (!dataView.values || !dataView.values.length) {
        return options;
    }
    const hasActual = hasRoleInDataView(dataView, MeasureRoles.Values);
    const hasPreviousYear = hasRoleInDataView(dataView, MeasureRoles.PreviousYear);
    const hasPlan = hasRoleInDataView(dataView, MeasureRoles.Plan);
    const hasForecast = hasRoleInDataView(dataView, MeasureRoles.Forecast);
    const hasComments = hasRoleInDataView(dataView, MeasureRoles.Comments);

    options.hasActual = hasActual;
    options.hasPreviousYear = hasPreviousYear;
    options.hasPlan = hasPlan;
    options.hasForecast = hasForecast;

    if (hasActual) {
        options.valueScenario = Scenario.Actual;
        if (hasPlan) {
            options.referenceScenario = Scenario.Plan;
            if (hasForecast && hasPreviousYear) {
                options.secondValueScenario = Scenario.Forecast;
                options.secondReferenceScenario = Scenario.PreviousYear;
            }
            else if (hasForecast) {
                options.secondValueScenario = Scenario.Forecast;
            }
            else if (hasPreviousYear) {
                options.secondReferenceScenario = Scenario.PreviousYear;
            }
        }
        else if (hasForecast) {
            if (hasPreviousYear) {
                options.secondValueScenario = Scenario.Forecast;
                options.referenceScenario = Scenario.PreviousYear;
            }
            else {
                if (isFCSecondSegment(dataView)) {
                    options.secondValueScenario = Scenario.Forecast;
                }
                else {
                    options.referenceScenario = Scenario.Forecast;
                }
            }
        }
        else if (hasPreviousYear) {
            options.referenceScenario = Scenario.PreviousYear;
        }
    }
    else if (hasPlan) {
        if (hasForecast && hasPreviousYear) {
            options.valueScenario = Scenario.Forecast;
            options.referenceScenario = Scenario.Plan;
            options.secondReferenceScenario = Scenario.PreviousYear;
        }
        else if (hasForecast) {
            options.referenceScenario = Scenario.Plan;
            options.valueScenario = Scenario.Forecast;
        }
        else if (hasPreviousYear) {
            options.referenceScenario = Scenario.Plan;
            options.valueScenario = Scenario.PreviousYear;
        }
        else {
            options.valueScenario = Scenario.Plan;
        }
    }
    else if (hasForecast) {
        options.valueScenario = Scenario.Forecast;
        if (hasPreviousYear) {
            options.referenceScenario = Scenario.PreviousYear;
        }
    }
    else if (hasPreviousYear) {
        options.valueScenario = Scenario.PreviousYear;
    }

    if (hasComments) {
        const commentsIndex = getScenarioIndex(dataView, MeasureRoles.Comments);
        options.commentsIndices.push(commentsIndex);
    }

    if (options.hasActual) {
        const hasSecondActualValues = dataView.metadata.columns.filter(c => c.roles && c.roles.Values).length > 1;
        if (hasSecondActualValues) {
            options.secondActualValueIndex = dataView.metadata.columns.filter(c => c.roles?.Values)[1].index;
        }
    }

    if (options.valueScenario !== null) {
        options.valueIndex = getScenarioIndex(dataView, getScenarioRoleString(options.valueScenario));
    }
    if (options.referenceScenario !== null) {
        options.referenceIndex = getScenarioIndex(dataView, getScenarioRoleString(options.referenceScenario));
    }
    if (options.secondReferenceScenario !== null) {
        options.secondReferenceIndex = getScenarioIndex(dataView, getScenarioRoleString(options.secondReferenceScenario));
    }
    if (options.secondValueScenario !== null) {
        options.secondValueIndex = getScenarioIndex(dataView, getScenarioRoleString(options.secondValueScenario));
    }

    return options;
}

export function setDefaultSettingsScenarioHeaders(scenarioOptions: ScenarioOptions): ChartSettings {
    const settingsHeaderNull = getEmptyScenarioHeaders();
    setCurrentSettingsScenarioHeaders(settingsHeaderNull, scenarioOptions);
    return settingsHeaderNull;
}

export function setCurrentSettingsScenarioHeaders(settings: ChartSettings, scenarioOptions: ScenarioOptions) {
    // copied here from library since the values that are used as property names were modified
    const VALUE_SCENARIO = "valueScenario";
    const REFERENCE_SCENARIO = "referenceScenario";
    const SECOND_VALUE_SCENARIO = "secondValueScenario";
    const SECOND_REFERENCE_SCENARIO = "secondReferenceScenario";

    setHeaderValue(settings, scenarioOptions, VALUE_HEADER, VALUE_SCENARIO, AC, PL, FC, PY);
    if (scenarioOptions.referenceScenario !== null) {
        setHeaderValue(settings, scenarioOptions, ABSOLUTE_DIFFERENCE_HEADER, REFERENCE_SCENARIO, DELTA_AC, DELTA_PL, DELTA_FC, DELTA_PY);
        setHeaderValue(settings, scenarioOptions, RELATIVE_DIFFERENCE_HEADER, REFERENCE_SCENARIO, DELTA_AC_PERCENT, DELTA_PL_PERCENT, DELTA_FC_PERCENT, DELTA_PY_PERCENT);
        setHeaderValue(settings, scenarioOptions, REFERENCE_HEADER, REFERENCE_SCENARIO, AC, PL, FC, PY);
    }
    if (scenarioOptions.secondReferenceScenario !== null) {
        setHeaderValue(settings, scenarioOptions, SECOND_ABSOLUTE_DIFFERENCE_HEADER, SECOND_REFERENCE_SCENARIO, DELTA_AC, DELTA_PL, DELTA_FC, DELTA_PY);
        setHeaderValue(settings, scenarioOptions, SECOND_RELATIVE_DIFFERENCE_HEADER, SECOND_REFERENCE_SCENARIO, DELTA_AC_PERCENT, DELTA_PL_PERCENT, DELTA_FC_PERCENT, DELTA_PY_PERCENT);
        setHeaderValue(settings, scenarioOptions, SECOND_REFERENCE_HEADER, SECOND_REFERENCE_SCENARIO, AC, PL, FC, PY);
    }
    if (scenarioOptions.secondValueScenario !== null) {
        setHeaderValue(settings, scenarioOptions, SECOND_VALUE_HEADER, SECOND_VALUE_SCENARIO, AC, PL, FC, PY);
    }
}

export function getEmptyScenarioHeaders(): any {
    return {
        valueHeader: null,
        absoluteDifferenceHeader: null,
        relativeDifferenceHeader: null,
        referenceHeader: null,
        secondReferenceHeader: null,
        secondAbsoluteDifferenceHeader: null,
        secondRelativeDifferenceHeader: null,
        secondValueHeader: null
    };
}

export function getEmptyScenarioOptions(): ScenarioOptions {
    return {
        referenceScenario: null,
        valueScenario: null,
        secondValueScenario: null,
        secondReferenceScenario: null,
        referenceIndex: null,
        valueIndex: null,
        secondValueIndex: null,
        secondReferenceIndex: null,
        secondActualValueIndex: null,
        tooltipsIndices: [],
        commentsIndices: [],
        filterContextIndices: [],
        hasActual: false,
        hasPreviousYear: false,
        hasPlan: false,
        hasForecast: false
    };
}

function setHeaderValue(settings: ChartSettings, scenarioOptions: ScenarioOptions, headerProp: string, scenarioProp: string, ac: string, pl: string, fc: string, py: string) {
    if (settings[headerProp] == null) {
        if (scenarioOptions[scenarioProp] === Scenario.Actual) {
            settings[headerProp] = ac;
        }
        else if (scenarioOptions[scenarioProp] === Scenario.Plan) {
            settings[headerProp] = pl;
        }
        else if (scenarioOptions[scenarioProp] === Scenario.Forecast) {
            settings[headerProp] = fc;
        }
        else if (scenarioOptions[scenarioProp] === Scenario.PreviousYear) {
            settings[headerProp] = py;
        }
    }
}

export function getScenarioName(scenario: Scenario): string {
    switch (scenario) {
        case Scenario.Actual:
            return AC;
        case Scenario.PreviousYear:
            return PY;
        case Scenario.Plan:
            return PL;
        case Scenario.Forecast:
            return FC;
        default:
            return AC;
    }
}

export function getScenarioEnum(scenarioName: string): Scenario {
    switch (scenarioName) {
        case PY:
            return Scenario.PreviousYear;
        case PL:
            return Scenario.Plan;
        case FC:
            return Scenario.Forecast;
        default:
            return Scenario.Actual;
    }
}

export function switchReferenceScenarios(scenarioOptions: ScenarioOptions) {
    const tmpReferenceIndex = scenarioOptions.referenceIndex;
    const tmpReferenceScenario = scenarioOptions.referenceScenario;
    scenarioOptions.referenceIndex = scenarioOptions.secondReferenceIndex;
    scenarioOptions.referenceScenario = scenarioOptions.secondReferenceScenario;
    scenarioOptions.secondReferenceIndex = tmpReferenceIndex;
    scenarioOptions.secondReferenceScenario = tmpReferenceScenario;
}


function isFCSecondSegment(dataView: DataView): boolean {
    const acIndex = getScenarioIndex(dataView, MeasureRoles.Values);
    const fcIndex = getScenarioIndex(dataView, MeasureRoles.Forecast);
    let isSecondSegment = true;
    const grouppedValues = dataView.values.grouped();
    grouppedValues.forEach(g => {
        const acValues = g.values[acIndex].values;
        const fcValues = g.values[fcIndex].values;
        const lastNonNullAcValueIndex = getLastNonNullValueIndex(acValues);

        if (acValues.some((acValue, i) => acValue !== null && acValue !== "" && acValue !== fcValues[i] && (i < lastNonNullAcValueIndex || i === acValues.length - 1))) {
            isSecondSegment = false;
        }
    });

    return isSecondSegment;
}
