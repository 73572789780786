import { ColorScheme } from "./interfaces";
import {
    BLACK, CUSTOMER_POSITIVE_COLOR, CUSTOMER_NEGATIVE_COLOR, CUSTOMER_NEUTRAL_COLOR, CUSTOMER_MARKER_COLOR, CUSTOMER_LINE_COLOR, CUSTOMER_AXIS_COLOR, CUSTOMER_GRIDLINE_COLOR,
    CUSTOMER_DOT_CHART_COLOR, CUSTOMER_MORE_COLORS, CUSTOMER_PY_COLOR, CUSTOMER_PL_COLOR, CUSTOMER_FC_COLOR, CUSTOMER_APPLY_PATTERNS, HIGHLIGHT_COLOR
} from "./constants";
import { ChartStyle } from "@zebrabi/constants";

// eslint-disable-next-line max-lines-per-function
export function getColorSchemeFromStyle(chartStyle: ChartStyle): ColorScheme {
    switch (chartStyle) {
        case ChartStyle.Zebra:
            return {
                positiveColor: "#7aca00",
                negativeColor: "#ff0000",
                neutralColor: "#404040",
                markerColor: BLACK,
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.ZebraLight:
            return {
                positiveColor: "#7aca00",
                negativeColor: "#ff0000",
                neutralColor: "#DEDDC8",
                markerColor: "#404040",
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.DrHichert:
            return {
                positiveColor: "#8CB400",
                negativeColor: "#FF0000",
                neutralColor: "#404040",
                markerColor: BLACK,
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#b0b0b0",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.PowerBI:
            return {
                positiveColor: "#01B8AA",
                negativeColor: "#FD625E",
                neutralColor: "#374649",
                markerColor: BLACK,
                lineColor: "#374649",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.ColorblindSafe:
            return {
                positiveColor: "#6D97FF",
                negativeColor: "#000000",
                neutralColor: "#CCCCCC",
                markerColor: "#404040",
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.Company:
            return {
                positiveColor: CUSTOMER_POSITIVE_COLOR,
                negativeColor: CUSTOMER_NEGATIVE_COLOR,
                neutralColor: CUSTOMER_NEUTRAL_COLOR,
                markerColor: CUSTOMER_MARKER_COLOR,
                lineColor: CUSTOMER_LINE_COLOR,
                axisColor: CUSTOMER_AXIS_COLOR,
                gridlineColor: CUSTOMER_GRIDLINE_COLOR,
                majorGridlineColor: "#999",
                dotChartColor: CUSTOMER_DOT_CHART_COLOR,
                useCustomScenarioColors: CUSTOMER_MORE_COLORS.toString() === "true",
                previousYearColor: CUSTOMER_PY_COLOR,
                planColor: CUSTOMER_PL_COLOR,
                forecastColor: CUSTOMER_FC_COLOR,
                applyPatterns: CUSTOMER_APPLY_PATTERNS.toString() !== "false",
                highlightColor: HIGHLIGHT_COLOR,
            };
        default:
            return {
                positiveColor: "#7aca00",
                negativeColor: "#ff0000",
                neutralColor: "#404040",
                markerColor: BLACK,
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
    }
}

export function getVarianceColor(invert: boolean, value: number, scheme: ColorScheme): string {
    if (value < 0) {
        if (invert) {
            return scheme.positiveColor;
        }
        return scheme.negativeColor;
    }
    else {
        if (invert) {
            return scheme.negativeColor;
        }
        return scheme.positiveColor;
    }
}
