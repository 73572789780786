
export async function getSampleDataRangeStartRowAndColumn(activeWorksheet: Excel.Worksheet, context: Excel.RequestContext, sampleDataRowsCount: number): Promise<{ startRow: number, startColumn: number }> {
    let startRow = 0;
    let startColumn = 0;

    // check for stored named range address and use it for sample data location, if it exists
    const zebraNamedRange: Excel.NamedItem = activeWorksheet.names.getItemOrNullObject("ZBI_datasource_address");
    const usedRange = activeWorksheet.getUsedRangeOrNullObject();
    await context.sync();

    if (!zebraNamedRange.isNullObject) {
        zebraNamedRange.load({ value: true });
        await context.sync();
        try {
            const range = activeWorksheet.getRange(zebraNamedRange.value);
            range.load({ rowIndex: true, columnIndex: true });
            await context.sync();
            startRow = range.rowIndex;
            startColumn = range.columnIndex;
        } catch (error) {
            console.log("could not parse named range address: ", zebraNamedRange.value);
        }
    } else if (!usedRange.isNullObject) {
        const firstCell = usedRange.getCell(0, 0);
        firstCell.load({ address: true, rowIndex: true, columnIndex: true });

        const lastCell = usedRange.getLastCell();
        lastCell.load({ address: true, rowIndex: true, columnIndex: true });

        await context.sync();

        if (firstCell.rowIndex < sampleDataRowsCount + 2) {
            // no space at the top left, sample data needs to be placed at a different location
            if (lastCell.rowIndex < 50 || lastCell.columnIndex > 30) {
                startRow = lastCell.rowIndex + 4;
            } else {
                startColumn = lastCell.columnIndex + 4;
            }
        }
    }

    return { startRow, startColumn };
}

export async function getExcelTable(context: Excel.RequestContext, worksheet?: Excel.Worksheet, tableName?: string, tableId?: string): Promise<Excel.Table> {
    if (tableId || tableName) {
        return context.workbook.tables.getItem(tableId ? tableId : tableName); //sheet.tables.getItem(tableName);
    }

    const sheet = worksheet ? worksheet : context.workbook.worksheets.getActiveWorksheet();
    if (!sheet?.tables) {
        return null;
    }

    const tables = sheet.tables;
    const tablesCount = tables?.getCount();
    await context.sync();

    if (tablesCount.value === 0) {
        return null;
    } else {
        const table = tables.getItemAt(0);
        table.load({ id: true, name: true });
        await context.sync();
        return table;
    }
}

export function isValidRangeInputData(range: Excel.Range): boolean {
    const hasNumber = range.values.some(row => row.some(cell => typeof cell === "number"));
    const hasNumberType = range.valueTypes.some(row => row.some(type => type === Excel.RangeValueType.double || type === Excel.RangeValueType.integer));
    return range.cellCount > 2 && (hasNumber || hasNumberType);
}
