
import { DataPoint, ChartData, ViewModel, CategoryData } from "./../interfaces";
import { ShowTopNChartsOptions, ChartType } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { ACTUAL, ACTUAL_ABSOLUTE, ACTUAL_RELATIVE, RELATIVE, ABSOLUTE_RELATIVE, ABSOLUTE, ROWS_LAYOUT } from "./../consts";
import * as styles from "./../library/styles";
import * as statistics from "@zebrabi/legacy-library-common/outliersCalculation";
import * as d3 from "../d3";
import { getNumberOrNull } from "./viewModels";
import { sortDescending } from "../helpers";
import { PrimitiveValue } from "@zebrabi/table-data";

export function getDataPointNonNullValue(dataPoint: DataPoint): number {
    return dataPoint.value !== null ? dataPoint.value : dataPoint.secondSegmentValue;
}

export function calculateRelativeDifferencePercent(value: number, referenceValue: number): number {
    let relativeDifference = calculateRelativeDifference(value, referenceValue);
    if (relativeDifference !== null) {
        relativeDifference = relativeDifference * 100;
    }
    return relativeDifference;
}

export function calculateRelativeDifference(value: number, referenceValue: number): number {
    if (value === null || referenceValue === null || referenceValue === 0) {
        return null;
    }
    return (value - referenceValue) / Math.abs(referenceValue);
}

export function getVariance(dataPoint: DataPoint, useSecondReference: boolean, useSecondSegmentOnly: boolean, useOriginalValue: boolean = false): number {
    const reference = useSecondReference ? dataPoint.secondReference : dataPoint.reference;
    let value = dataPoint.value !== null ? (useOriginalValue ? dataPoint.originalValue : dataPoint.value) : dataPoint.secondSegmentValue;
    if (useSecondSegmentOnly && dataPoint.value !== null && dataPoint.secondSegmentValue !== null) {
        value = dataPoint.secondSegmentValue;
    }

    if (value === null || reference === null) {
        return null;
    }
    return value - reference;
}

// eslint-disable-next-line max-lines-per-function
export function replaceSmallerChartsWithOthers(viewModel: ViewModel, settings: ChartSettings): ViewModel {
    sortDescending(viewModel.chartData, item => item.max - item.min);
    const sortedCharts = viewModel.chartData;
    const topNOptions = settings.shouldPlotStackedChart(viewModel.isMultiples) ? settings.showTopNStackedOptions : settings.showTopNChartsOptions;
    const topNItemsToKeep = settings.shouldPlotStackedChart(viewModel.isMultiples) ? settings.topNStackedToKeep : settings.topNChartsToKeep;
    const topNPercentage = settings.shouldPlotStackedChart(viewModel.isMultiples) ? settings.topNStackedPercentage : settings.topNChartsPercentage;
    const topNOthersLabel = settings.shouldPlotStackedChart(viewModel.isMultiples) ? settings.topNStackedOthersLabel : settings.topNChartsOthersLabel;

    if (topNItemsToKeep === 0 && topNPercentage === 0) {
        return viewModel;
    }

    let numberOfChartsToKeep = topNItemsToKeep < sortedCharts.length ? topNItemsToKeep : sortedCharts.length;
    if (topNOptions === ShowTopNChartsOptions.Percentage && topNPercentage > 0) {
        const chartsSpanArray = sortedCharts.map(c => c.max - c.min);
        const totalSpan = d3.sum(chartsSpanArray);
        let currentSpan = 0;
        let currentIndex = 0;
        while (currentSpan < totalSpan * (topNPercentage / 100)) {
            currentSpan += chartsSpanArray[currentIndex];
            currentIndex++;
        }
        numberOfChartsToKeep = currentIndex;
    }

    const chartsToKeep = sortedCharts.slice(0, numberOfChartsToKeep);
    const otherCharts = sortedCharts.slice(numberOfChartsToKeep);
    if (otherCharts.length < 2) {
        return viewModel;
    }
    viewModel.chartData = chartsToKeep;
    const othersChartData: ChartData = otherCharts.reduce((othersCombined, current, j, arr) => {
        othersCombined.dataPoints.forEach((dp, i) => {
            if (settings.chartType === ChartType.Waterfall) {
                const currentVariance = current.dataPoints[i].value * (current.dataPoints[i].isVariance && current.dataPoints[i].isNegative ? -1 : 1);
                const cumulativeVariance = dp.value * (dp.value > 0 && dp.isVariance && dp.isNegative ? -1 : 1);
                dp.value = cumulativeVariance + currentVariance;
                dp.isNegative = dp.value < 0;
            }
            else {
                if (current.dataPoints[i].value !== null) {
                    dp.value += current.dataPoints[i].value;
                }
                if (current.dataPoints[i].secondReference !== null) {
                    dp.secondReference += current.dataPoints[i].secondReference;
                }
                if (current.dataPoints[i].secondSegmentValue !== null) {
                    dp.secondSegmentValue += current.dataPoints[i].secondSegmentValue;
                }
            }

            if (viewModel.isSingleSeriesViewModel && settings.chartType === ChartType.Waterfall) {
                dp.reference = dp.isNegative ? 0 : dp.value;
            }
            else {
                dp.reference += current.dataPoints[i].reference;
            }
            dp.selectionId = null;
        });
        return othersCombined;
    });
    othersChartData.group = topNOthersLabel;
    othersChartData.isOther = true;

    if (viewModel.isSingleSeriesViewModel && settings.chartType !== ChartType.Waterfall) {
        othersChartData.max = Math.max(0, Math.max(...othersChartData.dataPoints.map((v) => v.value)));
        othersChartData.min = Math.min(0, Math.min(...othersChartData.dataPoints.map((v) => v.value)));
    }
    else {
        if (settings.chartType === ChartType.Waterfall) {
            const resultDps = othersChartData.dataPoints.filter(p => !p.isVariance);
            resultDps.forEach((dp, i) => {
                dp.isNegative = dp.value < 0;
                dp.startPosition = dp.value < 0 ? 0 : dp.value;
            });

            const varianceDps = othersChartData.dataPoints.filter(p => p.isVariance);
            varianceDps.forEach((dp, i) => {
                dp.isNegative = dp.value < 0;
                dp.value = Math.abs(dp.value);
                dp.color = styles.getVarianceColor(settings.getInvert(othersChartData.group), dp.value * (dp.isNegative ? -1 : 1), settings.colorScheme);
                if (viewModel.isSingleSeriesViewModel) {
                    const dpIndex = othersChartData.dataPoints.indexOf(dp);
                    if (i === 0 && dpIndex === 0) {
                        dp.startPosition = dp.isNegative ? 0 : dp.value;
                    }
                    else if (dpIndex > 0 && !othersChartData.dataPoints[dpIndex - 1].isVariance) {
                        dp.startPosition = othersChartData.dataPoints[dpIndex - 1].startPosition + (dp.isNegative ? 0 : dp.value);
                    }
                    else {
                        const previousDp = othersChartData.dataPoints[dpIndex - 1];
                        dp.startPosition = previousDp.startPosition + (previousDp.isNegative ? -1 : 0) * previousDp.value + (dp.isNegative ? 0 : dp.value);
                    }
                }
                else {
                    const previousDp = othersChartData.dataPoints[i];
                    dp.startPosition = i === 0 ? (previousDp.isNegative ? previousDp.value : previousDp.startPosition) + (dp.isNegative ? 0 : dp.value) :
                        (previousDp.startPosition + (previousDp.isNegative ? -previousDp.value : 0) + (dp.isNegative ? 0 : dp.value));
                }
            });

            othersChartData.max = Math.max(0, ...othersChartData.dataPoints.map(d => d.startPosition));
            othersChartData.min = Math.min(0, ...othersChartData.dataPoints.map(d => d.startPosition + d.value * (d.isNegative && d.isVariance ? -1 : 1)));
        }
        else {
            othersChartData.dataPoints.forEach(p => {
                let relVariance = calculateRelativeDifference(p.value, p.reference);
                if (relVariance === null) {
                    relVariance = calculateRelativeDifference(p.secondSegmentValue, p.reference);
                }
                p.relativeVariance = relVariance;
                if (settings.scenarioOptions.secondReferenceIndex !== null) {
                    p.secondReferenceRelativeVariance = calculateRelativeDifference(getDataPointNonNullValue(p), p.secondReference);
                }
            });
            othersChartData.max = Math.max(0, ...othersChartData.dataPoints.map(p => Math.max(p.reference, p.secondReference, p.value, p.secondSegmentValue)));
            othersChartData.min = Math.min(0, ...othersChartData.dataPoints.map(p => Math.min(p.reference, p.secondReference, p.value, p.secondSegmentValue)));
            let variances = othersChartData.dataPoints.map(d => getVariance(d, false, false));
            if (settings.showAllForecastData) {
                variances = variances.concat(othersChartData.dataPoints.map(d => getVariance(d, false, true)));
            }
            othersChartData.maxVariance = Math.max(...variances, 0);
            othersChartData.minVariance = Math.min(...variances, 0);
            othersChartData.maxRelativeVariance = Math.max(...othersChartData.dataPoints.map((d) => d.relativeVariance), 0);
            othersChartData.minRelativeVariance = Math.min(...othersChartData.dataPoints.map((d) => d.relativeVariance), 0);
            calculateOutliers(othersChartData, settings);
            if (settings.scenarioOptions.secondReferenceIndex !== null) {
                let secondReferenceVariances = othersChartData.dataPoints.map(d => getVariance(d, true, false));
                if (settings.showAllForecastData) {
                    secondReferenceVariances = secondReferenceVariances.concat(othersChartData.dataPoints.map(d => getVariance(d, true, true)));
                }
                othersChartData.maxSecondReferenceVariance = Math.max(...secondReferenceVariances, 0);
                othersChartData.minSecondReferenceVariance = Math.min(...secondReferenceVariances, 0);
                othersChartData.maxSecondReferenceRelativeVariance = Math.max(...othersChartData.dataPoints.map((d) => d.secondReferenceRelativeVariance), 0);
                othersChartData.minSecondReferenceRelativeVariance = Math.min(...othersChartData.dataPoints.map((d) => d.secondReferenceRelativeVariance), 0);
            }
            const isPlusMinus = settings.chartType === ChartType.PlusMinus
                || settings.chartType === ChartType.Variance && settings.chartLayout === ABSOLUTE
                || settings.chartType === ChartType.Variance && settings.chartLayout === ABSOLUTE_RELATIVE && settings.multiplesLayoutType === ROWS_LAYOUT;
            if (isPlusMinus) {
                othersChartData.min = othersChartData.minVariance;
                othersChartData.max = othersChartData.maxVariance;
            }
            const isPlusMinusDot = settings.chartType === ChartType.PlusMinusDot || settings.chartType === ChartType.Variance && settings.chartLayout === RELATIVE;
            if (isPlusMinusDot) {
                othersChartData.min = othersChartData.minOutlierValue;
                othersChartData.max = othersChartData.maxOutlierValue;
            }
            const isNonOverlappedActualLayout = !settings.plotOverlappedReference && (settings.chartType === ChartType.Variance && settings.chartLayout === ACTUAL
                || settings.chartType === ChartType.Variance && settings.chartLayout === ACTUAL_ABSOLUTE
                || settings.chartType === ChartType.Variance && settings.chartLayout === ACTUAL_RELATIVE);
            if (isNonOverlappedActualLayout) {
                othersChartData.max = Math.max(0, ...othersChartData.dataPoints.map(p => Math.max(p.value === null ? p.secondSegmentValue : p.value)));
                othersChartData.min = Math.min(0, ...othersChartData.dataPoints.map(p => Math.min(p.value === null ? p.secondSegmentValue : p.value)));
            }
        }
    }
    viewModel.chartData.push(othersChartData);
    return viewModel;
}

export function calculateOutliers(chartData: ChartData, settings: ChartSettings) {
    let [minOffsetValue, maxOffsetValue] = statistics.outliersCalculation(chartData.dataPoints.map(p => p.relativeVariance));
    if (settings.limitOutliers && settings.minOutlierValue !== null) {
        minOffsetValue = -1 * Math.abs(settings.minOutlierValue) / 100;
    }
    if (settings.limitOutliers && settings.maxOutlierValue !== null) {
        maxOffsetValue = Math.abs(settings.maxOutlierValue) / 100;
    }
    chartData.minOutlierValue = Math.max(chartData.minRelativeVariance, isNaN(minOffsetValue) ? chartData.minRelativeVariance : minOffsetValue);
    chartData.maxOutlierValue = Math.min(chartData.maxRelativeVariance, isNaN(maxOffsetValue) ? chartData.maxRelativeVariance : maxOffsetValue);
}

// eslint-disable-next-line max-lines-per-function
export function getTopNCategoriesSingleSeriesValues(topNCategoriesToKeep: number, categoriesData: CategoryData[], valuesOrig: PrimitiveValue[], othersLabel: string, othersSorted: boolean = false) {
    let newCategoriesData: CategoryData[] = [];
    let newValues: PrimitiveValue[] = [];
    let otherCategoriesData: CategoryData[] = [];

    // count amount of results.
    let numOfResults = 0;
    categoriesData.forEach(cd => {
        if (cd.isResult && !cd.isFloatingResult) {
            numOfResults++;
        }
    });

    //perform topN numOfResults times + 1
    let arrayIndex = 0;
    let otherIndex = 1;
    let firstOtherArrayIndex = -1;

    const getValueOrInverted = (value: PrimitiveValue, i: number, arr: PrimitiveValue[]) =>
        arr[i] = getNumberOrNull(value) !== null ? (categoriesData[i].isInverted ? -1 : 1) * <number>value : null;
    const valuesCopy = [...valuesOrig];
    valuesCopy.forEach(getValueOrInverted);

    for (let i = 0; i < numOfResults + 1; i++) {
        //get all points from after the previous result (or the beginning) up until the next result (or the end)
        const startIndex = arrayIndex;
        while (arrayIndex < categoriesData.length && (!categoriesData[arrayIndex].isResult || categoriesData[arrayIndex].isFloatingResult)) {
            arrayIndex++;
        }

        //we got to a result (or the end), get all the points from the previous segment
        const currentSegmentValues = valuesCopy.map((v, i) => { return { index: i, value: v }; }).slice(startIndex, arrayIndex);

        if (topNCategoriesToKeep >= currentSegmentValues.length) {
            currentSegmentValues.forEach(v => {
                newValues.push(valuesOrig[v.index]);
                newCategoriesData.push(categoriesData[v.index]);
            });
        } else {
            //get top N of current segment points
            const sortedVals = [...currentSegmentValues].sort((v1, v2) => Math.abs(<number>v2.value) - Math.abs(<number>v1.value));
            const valuesToKeep = sortedVals.slice(0, topNCategoriesToKeep);
            const indexesToKeep = currentSegmentValues.map(v => v.index).filter(v => valuesToKeep.map(c => c.index).indexOf(v) > -1);

            const valuesToCombineIntoOthers = sortedVals.slice(topNCategoriesToKeep);
            const indexesToCombineIntoOthers = valuesToCombineIntoOthers.map(v => v.index);

            const tempNewValues: PrimitiveValue[] = [];
            const tempNewCategoriesData: CategoryData[] = [];
            indexesToKeep.forEach(i => {
                tempNewValues.push(valuesOrig[i]);
                tempNewCategoriesData.push(categoriesData[i]);
            });

            // Add other categories data and values
            const tempOtherCategoriesData: CategoryData[] = [];
            indexesToCombineIntoOthers.forEach(i => {
                tempOtherCategoriesData.push(categoriesData[i]);
            });

            otherCategoriesData = tempOtherCategoriesData;

            const othersCombinedValue = d3.sum(<number[]>valuesCopy.filter((v, i) => indexesToCombineIntoOthers.indexOf(i) !== -1));
            if (othersSorted) {
                let insertOthersIndex = 0;
                while (insertOthersIndex < valuesToKeep.length && <number>valuesToKeep[insertOthersIndex].value > othersCombinedValue) {
                    insertOthersIndex++;
                }
                tempNewValues.splice(insertOthersIndex, 0, othersCombinedValue);
                tempNewCategoriesData.splice(insertOthersIndex, 0, { category: othersLabel + (otherIndex === 1 ? "" : " " + otherIndex), isInverted: false, isOther: true });

                if (otherIndex === 1) {
                    firstOtherArrayIndex = newCategoriesData.length + insertOthersIndex;
                } else if (otherIndex === 2) {
                    newCategoriesData[firstOtherArrayIndex].category = othersLabel + " 1";
                }
                newValues = newValues.concat(tempNewValues);
                newCategoriesData = newCategoriesData.concat(tempNewCategoriesData);
                otherIndex++;
            }
            else {
                newValues = newValues.concat(tempNewValues);
                newCategoriesData = newCategoriesData.concat(tempNewCategoriesData);
                newValues.push(othersCombinedValue);
                newCategoriesData.push({ category: othersLabel + (otherIndex === 1 ? "" : " " + otherIndex), isInverted: false, isOther: true });
                if (otherIndex === 1) {
                    firstOtherArrayIndex = newCategoriesData.length - 1;
                } else if (otherIndex === 2) {
                    newCategoriesData[firstOtherArrayIndex].category = othersLabel + " 1";
                }
                otherIndex++;
            }
        }

        //push also the last result, if it exists
        if (arrayIndex < valuesCopy.length) {
            newValues.push(valuesOrig[arrayIndex]);
            newCategoriesData.push(categoriesData[arrayIndex]);
        }
        arrayIndex++;
    }

    return {
        newValues: newValues,
        newCategoriesData: newCategoriesData,
        otherCategoriesData: otherCategoriesData,
    };
}

// eslint-disable-next-line max-lines-per-function
export function getTopNCategoriesViewModelValues(topNCategoriesToKeep: number, categoriesData: CategoryData[],
    values: PrimitiveValue[], referenceValues: PrimitiveValue[], secondReferenceValues: PrimitiveValue[], secondSegmentValues: PrimitiveValue[],
    othersLabel: string, othersSorted: boolean = true) {

    const newValues: PrimitiveValue[] = [];
    const newReferenceValues: PrimitiveValue[] = [];
    const newSecondReferenceValues: PrimitiveValue[] = [];
    const newSecondSegmentValues: PrimitiveValue[] = [];
    const hasSecondSegment = secondSegmentValues && secondSegmentValues.length > 0;
    const hasSecondReference = secondReferenceValues && secondReferenceValues.length > 0;
    const newCategoriesData: CategoryData[] = [];
    let otherCategoriesData: CategoryData[] = [];

    const variances = referenceValues.map((referenceValue, i) => {
        const value = values[i] === null && hasSecondSegment ? secondSegmentValues[i] : values[i];
        return {
            index: i,
            variance: (categoriesData[i].isInverted ? -1 : 1) * (getNumberOrNull(value) - getNumberOrNull(referenceValue)),
            isSecondSegment: values[i] === null && hasSecondSegment,
        };
    });

    if (topNCategoriesToKeep >= variances.length) {
        // sort only
        let sortedVariances = variances.sort((v1, v2) => v2.variance - v1.variance);
        if (hasSecondSegment) {
            sortedVariances = variances.sort((v1, v2) => v2.isSecondSegment === v1.isSecondSegment ? v2.variance - v1.variance :
                ((v2.isSecondSegment ? 0 : 1) - (v1.isSecondSegment ? 0 : 1)));
        }
        sortedVariances.forEach(v => {
            newValues.push(values[v.index]);
            newReferenceValues.push(referenceValues[v.index]);
            if (hasSecondReference) {
                newSecondReferenceValues.push(secondReferenceValues[v.index]);
            }
            if (hasSecondSegment) {
                newSecondSegmentValues.push(secondSegmentValues[v.index]);
            }
            newCategoriesData.push(categoriesData[v.index]);
        });

        return {
            newValues: newValues,
            newReferenceValues: newReferenceValues,
            newCategoriesData: newCategoriesData,
            newSecondReferenceValues: newSecondReferenceValues,
            newSecondSegmentValues: newSecondSegmentValues,
        };
    }

    const sortedAbsVariances = variances.sort((v1, v2) => Math.abs(v2.variance) - Math.abs(v1.variance));
    let variancesToKeep = sortedAbsVariances.slice(0, topNCategoriesToKeep);
    variancesToKeep.sort((v1, v2) => v2.variance - v1.variance);
    if (hasSecondSegment) {
        variancesToKeep = variancesToKeep.sort((v1, v2) => v2.isSecondSegment === v1.isSecondSegment ? v2.variance - v1.variance :
            ((v2.isSecondSegment ? 0 : 1) - (v1.isSecondSegment ? 0 : 1)));
    }
    const indexesToKeep = variancesToKeep.map(v => v.index);
    const variancesToCombineIntoOthers = sortedAbsVariances.slice(topNCategoriesToKeep);
    const indexesToCombineIntoOthers = variancesToCombineIntoOthers.map(v => v.index);

    // Add other categories data and values
    const tempOtherCategoriesData: CategoryData[] = [];
    indexesToCombineIntoOthers.forEach(i => {
        tempOtherCategoriesData.push(categoriesData[i]);
    });

    otherCategoriesData = tempOtherCategoriesData;

    indexesToKeep.forEach(i => {
        newValues.push(values[i]);
        newReferenceValues.push(referenceValues[i]);
        if (hasSecondReference) {
            newSecondReferenceValues.push(secondReferenceValues[i]);
        }
        if (hasSecondSegment) {
            newSecondSegmentValues.push(secondSegmentValues[i]);
        }
        newCategoriesData.push(categoriesData[i]);
    });

    const getValueOrInverted = (value: PrimitiveValue, i: number, arr: PrimitiveValue[]) =>
        arr[i] = value !== null ? (categoriesData[i].isInverted ? -1 : 1) * <number>value : null;
    values.forEach(getValueOrInverted);
    referenceValues.forEach(getValueOrInverted);
    if (hasSecondSegment) {
        secondSegmentValues.forEach(getValueOrInverted);
    }
    if (hasSecondReference) {
        secondReferenceValues.forEach(getValueOrInverted);
    }

    const othersCombinedValue = d3.sum(<number[]>values.filter((v, i) => indexesToCombineIntoOthers.indexOf(i) !== -1));
    const othersCombinedReference = d3.sum(<number[]>referenceValues.filter((v, i) => indexesToCombineIntoOthers.indexOf(i) !== -1));
    const othersCombinedSecondSegmentValue = hasSecondSegment ? d3.sum(<number[]>(secondSegmentValues.filter((v, i) =>
        indexesToCombineIntoOthers.indexOf(i) !== -1 && values[i] === null))) : 0;
    const othersCombinedSecondReferenceValue = hasSecondReference ? d3.sum(<number[]>(secondReferenceValues.filter((v, i) =>
        indexesToCombineIntoOthers.indexOf(i) !== -1))) : 0;

    if (othersSorted) {
        const othersVariance = othersCombinedValue + othersCombinedSecondSegmentValue - othersCombinedReference;
        let insertOthersIndex = 0;
        while (insertOthersIndex < variancesToKeep.length && variancesToKeep[insertOthersIndex].variance > othersVariance) {
            insertOthersIndex++;
        }
        newValues.splice(insertOthersIndex, 0, othersCombinedValue + othersCombinedSecondSegmentValue);
        newReferenceValues.splice(insertOthersIndex, 0, othersCombinedReference);
        newCategoriesData.splice(insertOthersIndex, 0, { category: othersLabel, isInverted: false, isOther: true });
        if (hasSecondReference) {
            newSecondReferenceValues.splice(insertOthersIndex, 0, othersCombinedSecondReferenceValue);
        }
        if (hasSecondSegment) {
            newSecondSegmentValues.splice(insertOthersIndex, 0, othersCombinedSecondSegmentValue);
        }
    }
    else {
        newValues.push(othersCombinedValue + othersCombinedSecondSegmentValue);
        newReferenceValues.push(othersCombinedReference);
        newCategoriesData.push({ category: othersLabel, isInverted: false, isOther: true });
        if (hasSecondReference) {
            newSecondReferenceValues.push(othersCombinedSecondReferenceValue);
        }
        if (hasSecondSegment) {
            newSecondSegmentValues.push(othersCombinedSecondSegmentValue);
        }
    }

    return {
        newValues: newValues,
        newReferenceValues: newReferenceValues,
        newCategoriesData: newCategoriesData,
        newSecondReferenceValues: newSecondReferenceValues,
        newSecondSegmentValues: newSecondSegmentValues,
        otherCategoriesData: otherCategoriesData
    };
}

export function adjustViewModelForAxisBreak(viewModel: ViewModel, percent: number) {
    if (viewModel.settings.chartType === ChartType.Waterfall) {
        adjustWaterfallViewModelForAxisBreak(viewModel, percent);
    }
    else {
        adjustvarianceViewModelForAxisBreak(viewModel, percent);
    }
}

function adjustvarianceViewModelForAxisBreak(viewModel: ViewModel, percent: number) {
    for (let i = 0; i < viewModel.chartData.length; i++) {
        const chartData = viewModel.chartData[i];
        const axisBreakValue = getChartAxisBreakValue(chartData, viewModel.isSingleSeriesViewModel) * (percent / 100);
        chartData.axisBreak = axisBreakValue;
        for (let j = 0; j < chartData.dataPoints.length; j++) {
            if (chartData.dataPoints[j].value !== null) {
                chartData.dataPoints[j].value -= axisBreakValue;
            }
            if (chartData.dataPoints[j].reference !== null) {
                chartData.dataPoints[j].reference -= axisBreakValue;
            }
            if (chartData.dataPoints[j].secondReference !== null) {
                chartData.dataPoints[j].secondReference -= axisBreakValue;
            }
            if (chartData.dataPoints[j].secondSegmentValue !== null) {
                chartData.dataPoints[j].secondSegmentValue -= axisBreakValue;
            }
        }
        if (axisBreakValue > 0) {
            chartData.max -= axisBreakValue;
        }
        else {
            chartData.min -= axisBreakValue;
        }
    }
}

function getChartAxisBreakValue(chartData: ChartData, isSingleSeriesViewModel: boolean): number {
    const dataPoints = chartData.dataPoints;
    let axisBreak = 0;
    if (isSingleSeriesViewModel) {
        if (dataPoints.every(d => d.value < 0)) {
            axisBreak = Math.max(...chartData.dataPoints.map(dp => dp.value));
        }
        else if (dataPoints.every(d => d.value > 0)) {
            axisBreak = Math.min(...chartData.dataPoints.map(dp => dp.value));
        }
    }
    else {
        if (dataPoints.every(d => (getDataPointNonNullValue(d) < 0 || d.value === null && d.secondSegmentValue === null)
            && (d.reference === null || d.reference < 0) && (d.secondReference === null || d.secondReference < 0))) {
            axisBreak = getChartMinAxisBreakValue(chartData);
        }
        else if (dataPoints.every(d => (getDataPointNonNullValue(d) > 0 || d.value === null && d.secondSegmentValue === null)
            && (d.reference === null || d.reference > 0) && (d.secondReference === null || d.secondReference > 0))) {
            axisBreak = getChartMaxAxisBreakValue(chartData);
        }
    }

    return axisBreak;
}

function getChartMaxAxisBreakValue(chartData: ChartData): number {
    return Math.min(...chartData.dataPoints.map(dp => getVarianceDataPointMinNonNullValue(dp)));
}

function getChartMinAxisBreakValue(chartData: ChartData): number {
    return Math.max(...chartData.dataPoints.map(dp => getVarianceDataPointMaxNonNullValue(dp)));
}

function getVarianceDataPointMaxNonNullValue(dataPoint: DataPoint): number {
    return Math.max(...[getDataPointNonNullValue(dataPoint), dataPoint.reference, dataPoint.secondReference].filter(d => d !== null));
}

function getVarianceDataPointMinNonNullValue(dataPoint: DataPoint): number {
    return Math.min(...[getDataPointNonNullValue(dataPoint), dataPoint.reference, dataPoint.secondReference].filter(d => d !== null));
}

function adjustWaterfallViewModelForAxisBreak(viewModel: ViewModel, percent: number) {
    for (let i = 0; i < viewModel.chartData.length; i++) {
        const chartData = viewModel.chartData[i];
        const axisBreakValue = getWaterfallChartAxisBreakValue(chartData) * (percent / 100);
        chartData.axisBreak = axisBreakValue;
        for (let j = 0; j < chartData.dataPoints.length; j++) {
            chartData.dataPoints[j].startPosition -= axisBreakValue;
            if (!chartData.dataPoints[j].isVariance) {
                if (axisBreakValue < 0) {
                    chartData.dataPoints[j].startPosition = 0;
                }
                chartData.dataPoints[j].value -= axisBreakValue;
            }
        }

        if (axisBreakValue > 0) {
            chartData.max -= axisBreakValue;
        }
        else {
            chartData.min -= axisBreakValue;
        }
    }
}

function getWaterfallChartAxisBreakValue(chartData: ChartData): number {
    const dataPoints = chartData.dataPoints;
    let axisBreak = 0;
    const resultDataPoints = dataPoints.filter(p => !p.isVariance);
    if (dataPoints[0].isVariance || resultDataPoints.length === 0) {
        return axisBreak;
    }
    if (resultDataPoints.every(p => p.isNegative)) {
        axisBreak = Math.min(0, getWaterfallChartMinAxisBreakValue(chartData));
    }
    if (resultDataPoints.every(p => !p.isNegative)) {
        axisBreak = Math.max(0, getWaterfallChartMaxAxisBreakValue(chartData));
    }
    return axisBreak;
}

function getWaterfallChartMaxAxisBreakValue(chartData: ChartData): number {
    return Math.min(...chartData.dataPoints.filter(p => p.startPosition !== null).map(dp => dp.isVariance ? (dp.isNegative ? -1 : 1) * dp.value + dp.startPosition : dp.startPosition));
}

function getWaterfallChartMinAxisBreakValue(chartData: ChartData): number {
    return Math.max(...chartData.dataPoints.filter(p => p.startPosition !== null).map(dp => dp.isVariance ? dp.startPosition : dp.value));
}

export function getWaterfallChartDataPointCumulativeValue(p: DataPoint): number {
    return p.isVariance ? (p.isNegative ? -1 : 0) * p.value + p.startPosition : p.startPosition;
}

export function escapeCharacter(string) {
    return string.replace(/[-/\\^$*<>~#`!@%=:"';0123456789&*+?.()|[\]{}]/g, "\\$&");
}
