//import { DifferenceLabel } from "@zebrabi/constants"; //import { DifferenceLabel } from "./library/constants"; 
import { ChartSettings } from "./settings/chartSettings";
import { Visual } from "./visual";
import { ChartType, InteractionHandlers } from "@zebrabi/charts";
import { DifferenceLabel } from "@zebrabi/constants";
import { DataLabelSettings, DifferenceHighlightSettings, GlobalCategorySettings, GlobalLegendSettings, GlobalStackedChartSettings } from "@zebrabi/charts/dist/lib/typings";
import { TitleStyle } from "@zebrabi/charts/dist/lib/charting/title";

export default function initializeInteractionHandlers(chartsVisual: Visual): InteractionHandlers {
    return {
        onSlideSwitched: (chartType: ChartType): void => {
            const settings = chartsVisual.getSettings();
            settings.chartType = chartType;
            chartsVisual.constructViewModelAndVisualUpdate(settings);
        },
        onAxisBreakChanged: (hasAxisBreak: boolean): void => {
            const settings = chartsVisual.getSettings();
            settings.hasAxisBreak = hasAxisBreak;
            chartsVisual.constructViewModelAndVisualUpdate(settings);
        },
        onDataLabelsChanged: (varianceLabel: DifferenceLabel): void => {
            const settings = chartsVisual.getSettings();
            settings.varianceLabel = varianceLabel;
            chartsVisual.constructViewModelAndVisualUpdate(settings);
        },
        onDataLabelSettingsChanged: (dataLabelSettings: DataLabelSettings): void => {
            const settings = chartsVisual.getSettings();

            for (const [key, value] of Object.entries(dataLabelSettings)) {
                const settingsValue = value as any;
                if (key === "showDataLabels") {
                    settings[key] = settingsValue;
                } else if (key === "fontColor") {
                    settings.labelFontColor = settingsValue;
                } else if (key === "units") {
                    settings.displayUnits = settingsValue;
                } else if (key === "decimalPlaces") {
                    settings[key] = settingsValue;
                } else if (key === "decimalPlacesPercentage") {
                    settings[key] = settingsValue;
                } else if (key === "percentageInLabel") {
                    settings.showPercentageInLabel = settingsValue;
                } else if (key === "negativeValuesFormat") {
                    settings[key] = settingsValue;
                } else if (key === "labelDensity") {
                    settings[key] = settingsValue;
                } else if (key === "percentagePointUnit") {
                    settings.labelPercentagePointUnit = settingsValue;
                } else if (key === "fontSize") {
                    settings.labelFontSize = settingsValue;
                } else if (key === "fontFamily") {
                    settings.labelFontFamily = settingsValue;
                } else if (key === "labelBackgroundTransparency") {
                    settings[key] = settingsValue;
                } else {
                    settings[key] = value;
                }
            }

            //todo: check if ChartsVisual.chartsInstance.getSettings() could be used and avoid setting the new settings values
            chartsVisual.constructViewModelAndVisualUpdate(settings);
        },
        onDifferenceHighlightChanged: (differenceHighlight: boolean, differenceLabel: DifferenceLabel, showDifferenceHighlightSubtotals: boolean): void => {
            const settings = chartsVisual.getSettings();
            settings.differenceHighlight = differenceHighlight;
            settings.differenceLabel = differenceLabel;
            settings.showDifferenceHighlightSubtotals = showDifferenceHighlightSubtotals;
            chartsVisual.constructViewModelAndVisualUpdate(settings);
        },
        onDifferenceHighlightSettingsChanged: (differenceHighlightSettings: DifferenceHighlightSettings): void => {
            const settings = chartsVisual.getSettings();
            for (const [key, value] of Object.entries(differenceHighlightSettings)) {
                if (key === "differenceLabelType") {
                    settings.differenceLabel = <DifferenceLabel>value;
                } else {
                    settings[key] = value;
                }
            }
            chartsVisual.constructViewModelAndVisualUpdate(settings);
        },
        onFloatingResultCategoriesChanged: (floatingResultCategories: string[]): void => {
            chartsVisual.getSettings().floatingResultCategories = floatingResultCategories;
            chartsVisual.constructViewModelAndVisualUpdate(chartsVisual.getSettings());
        },
        onGlobalCategorySettingsChanged: (globalCategorySettings: GlobalCategorySettings): void => {
            chartsVisual.getSettings().persistGlobalCategorySettings(globalCategorySettings);
        },
        onGlobalLegendSettingsChanged: (globalLegendSettings: GlobalLegendSettings): void => {
            const settings = chartsVisual.getSettings();
            for (const [key, value] of Object.entries(globalLegendSettings)) {
                settings[key] = value;
            }
            chartsVisual.constructViewModelAndVisualUpdate(settings);
        },
        onGlobalStackedChartSettingsChanged: (globalStackedChartSettings: GlobalStackedChartSettings): void => {
            chartsVisual.getSettings().persistGlobalStackedChartSettings(globalStackedChartSettings);
        },
        onHighlightedCategoriesChanged: (highlightedCategories: string[]): void => {
            chartsVisual.getSettings().persistHighlightedCategoriesArray(highlightedCategories);
        },
        onHighlightedCategoriesCustomColorsChanged: (highlightedCategoriesCustomColors: object[]): void => {
            chartsVisual.getSettings().highlightedCategoriesCustomColors = highlightedCategoriesCustomColors;
            chartsVisual.constructViewModelAndVisualUpdate(chartsVisual.getSettings());
        },
        onHighlightedGroupsChanged: (highlightedGroups: string[]): void => {
            chartsVisual.getSettings().highlightedGroups = highlightedGroups;
            chartsVisual.constructViewModelAndVisualUpdate(chartsVisual.getSettings());
        },
        onHighlightedGroupsCustomColorsChanged: (highlightedGroupsCustomColors: object[]): void => {
            chartsVisual.getSettings().highlightedGroupsCustomColors = highlightedGroupsCustomColors;
            chartsVisual.constructViewModelAndVisualUpdate(chartsVisual.getSettings());
        },
        onInvertedCategoriesChanged: (invertedCategories: string[]): void => {
            chartsVisual.getSettings().persistInvertedCategoriesArray(invertedCategories);
        },
        onInvertedGroupsChanged: (invertedGroups: string[]): void => {
            chartsVisual.getSettings().invertedGroups = invertedGroups;
            chartsVisual.constructViewModelAndVisualUpdate(chartsVisual.getSettings());
        },
        onLegendEntriesChanged: (legendScenarioKey: string, persistedLabel: string): void => {
            chartsVisual.getSettings().persistLegendEntries(<any>legendScenarioKey, persistedLabel);
        },
        onMinChartHeightChanged: (minChartHeight: number): void => {
            chartsVisual.getSettings().minChartHeight = minChartHeight;
            chartsVisual.constructViewModelAndVisualUpdate(chartsVisual.getSettings());
        },
        onResetGlobalLegendSettings: (): void => {
            chartsVisual.getSettings().persistResetButtonLegendSettings();
        },
        onResultCategoriesChanged: (resultCategories: string[]): void => {
            chartsVisual.getSettings().persistIsResultCategoriesObject(resultCategories);
        },
        onScenarioCategoriesChanged: (scenarioCategories: object[]): void => {
            chartsVisual.getSettings().persistScenarioCategoriesObject(scenarioCategories);
        },
        onTitleStyleChanged: (style: TitleStyle): void => {
            const settings = chartsVisual.getSettings();
            settings.titleFontFamily = style.fontFamily;
            settings.titleFontSize = style.fontSize;
            settings.titleText = style.text;
            settings.titleFontColor = style.fontColor;
            settings.titleFontWeight = style.fontWeight;
            settings.titleFontStyle = style.fontStyle;
            settings.titleAlignment = style.alignment;
            settings.titleWrap = style.wrapLongTitle;
            chartsVisual.constructViewModelAndVisualUpdate(chartsVisual.getSettings());
        },
        onTopNCategorySettingsChanged: (topNCategoriesToKeep: number): void => {
            chartsVisual.getSettings().topNCategoriesToKeep = topNCategoriesToKeep;
            chartsVisual.constructViewModelAndVisualUpdate(chartsVisual.getSettings());
        },
        onTopNSettingsChanged: (topNChartsToKeep: number): void => {
            chartsVisual.getSettings().topNChartsToKeep = topNChartsToKeep;
            chartsVisual.constructViewModelAndVisualUpdate(chartsVisual.getSettings());
        },
        onTopNStackedItemsSettingsChanged: (topNStackedToKeep: number): void => {
            chartsVisual.getSettings().topNStackedToKeep = topNStackedToKeep;
            chartsVisual.constructViewModelAndVisualUpdate(chartsVisual.getSettings());
        },
        onStackedChartLabelsAsPercentageChanged: (showStackedLabelsAsPercentage: boolean): void => {
            chartsVisual.getSettings().showStackedLabelsAsPercentage = showStackedLabelsAsPercentage;
            chartsVisual.constructViewModelAndVisualUpdate(chartsVisual.getSettings());
        }
    };
}
