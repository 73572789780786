import {
  DefaultButton,
  ButtonStateTypes,
  DefaultButtonSubscriber,
  IconButton,
  InlineToggleButton,
  SplitButton,
  SplitButtonData,
  SplitButtonActions,
  SplitButtonSubscriber,
  IconName,
  ToggleButtonSubscriber,
  ToggleButtonData,
} from "@zebrabi/design-library";
import { dataLinkingEventBus } from "./DataLinkingEventBus";
import { mountReactExcelDataLinking } from "./react";
import { ExcelDataLinkingStore } from "./store";
import { signInService } from "@zebrabi/licensing/components/signin/MSFTSignIn";
import { licensing } from "@zebrabi/licensing/Licensing";
import SharepointBrowserAPI from "@zebrabi/sharepoint-api-browser/SharepointBrowserAPI";
import { transformSharePointResponse } from "./helpers";

export default class ExcelDataLinkingSwitcher {
  private readonly clickOutsideOverlayElement: HTMLDivElement = document.createElement("div"); // used for click event to close on click outside of dropdown menu
  private readonly buttonsDropdownMenu: HTMLDivElement = document.createElement("div");

  private lastDataRefreshText: HTMLDivElement;
  private errorMessageText: HTMLDivElement;
  
  private refreshData = new DefaultButton("Refresh data");
  private linkData = new DefaultButton("Link data to Excel");
  private editLinkData = new DefaultButton("Edit link", ButtonStateTypes.text, undefined, {
    iconBefore: IconName.EditLink,
  });

  private unlinkData = new DefaultButton("Remove link", ButtonStateTypes.text, undefined, {
    iconBefore: IconName.Unlink,
  });

  private autoRefreshDataToggle = new InlineToggleButton(false, { label: "Auto-refresh link data", name: "auto-refresh" });

  private splitButton = new SplitButton("Edit link", ButtonStateTypes.text); // fallback text - it will be replaced with an icon later on

  public appendElementsToHtml(node: HTMLElement) {
    this.clickOutsideOverlayElement.classList.add("excel-data-linking-dropdown-menu-backdrop", "hidden");
    document.body.appendChild(this.clickOutsideOverlayElement);

    this.buttonsDropdownMenu.classList.add("excel-data-linking-dropdown-menu", "hidden");
    document.body.appendChild(this.buttonsDropdownMenu);
    this.buttonsDropdownMenu.addEventListener("click", (event) => event.stopPropagation());

    this.appendHandlerToRefreshDataButton();
    this.appendHandlerToLinkDataButton();
    this.appendHandlerToUnlinkDataButton();
    this.appendHandlerToEditLinkDataButton();
    this.appendHandlerToSplitButton();
    this.appendHandlerToAutoRefreshDataToggle();

    const div = document.createElement("div");
    div.classList.add("excel-data-linking-buttons");
    node.appendChild(div);

    this.lastDataRefreshText = document.createElement("div");
    this.lastDataRefreshText.classList.add("excel-data-linking-last-refreshed");
    this.lastDataRefreshText.textContent = "Last refreshed: ";
    div.appendChild(this.lastDataRefreshText);

    this.errorMessageText = document.createElement("div");
    this.errorMessageText.classList.add("error-message");
    div.appendChild(this.errorMessageText);

    this.refreshData.appendTo(div);
    this.linkData.appendTo(div);

    this.splitButton.appendTo(div);
    this.editLinkData.appendTo(this.buttonsDropdownMenu);
    this.unlinkData.appendTo(this.buttonsDropdownMenu);
    this.autoRefreshDataToggle.appendTo(this.buttonsDropdownMenu);

    this.autoRefreshDataToggle.containerNode().title = "Automatically refresh link data when the Power Point file is opened";

    setTimeout(() => {
      this.replaceTextWithIconOnSplitButton();
    }, 10);
  }

  public updateButtonsStates() {
    const { worksheetId, workbookId, dataRefreshTimestamp, errorMessage, linkSourceDataFile } = ExcelDataLinkingStore.get().loaded;

    this.lastDataRefreshText.innerHTML = dataRefreshTimestamp ? `<span>Data last refreshed:</span><br/>${dataRefreshTimestamp.toLocaleString()}` : "";    
    if (dataRefreshTimestamp) {
      this.lastDataRefreshText.classList.remove("hidden");
      this.lastDataRefreshText.title = linkSourceDataFile ? `Data source: ${linkSourceDataFile.name}` : ""; //todo? \nupdated: ${new Date(linkSourceDataFile.lastModifiedDateTime).toLocaleString()}
    } else {
      this.lastDataRefreshText.classList.add("hidden");
    }

    if (errorMessage) {
      this.errorMessageText.textContent = `An error occurred while refreshing data: ${errorMessage}`;
      this.errorMessageText.classList.remove("hidden");
    } else {
      this.errorMessageText.classList.add("hidden");
    }

    this.autoRefreshDataToggle.inputNode().checked = ExcelDataLinkingStore.get().loaded.autoRefresh;

    if (worksheetId || workbookId) {
      this.linkData.buttonNode().classList.add("hidden");

      this.refreshData.buttonNode().classList.remove("hidden");
      this.splitButton.buttonNode().classList.remove("hidden");

      return;
    }

    this.linkData.buttonNode().classList.remove("hidden");

    this.refreshData.buttonNode().classList.add("hidden");
    this.splitButton.buttonNode().classList.add("hidden");
  }

  private appendHandlerToLinkDataButton() {
    const subscriber = new DefaultButtonSubscriber();

    subscriber.update = async (message) => {
      if (message.click) {
        (message.click as PointerEvent).preventDefault();

        await signInService.authenticateWithFilePermissions(licensing.getCurrentUser()?.organizationId);
        mountReactExcelDataLinking(signInService.getSharepointToken());
      }
    };

    this.linkData.buttonNode().style.alignSelf = "center";

    this.linkData.subscribe(subscriber);
  }

  private appendHandlerToEditLinkDataButton() {
    const subscriber = new DefaultButtonSubscriber();

    subscriber.update = async (message) => {
      if (message.click) {
        (message.click as PointerEvent).preventDefault();
        (message.click as PointerEvent).stopPropagation();
        this.hideDropdownMenu();

        await signInService.authenticateWithFilePermissions(licensing.getCurrentUser()?.organizationId);
        mountReactExcelDataLinking(signInService.getSharepointToken());
      }
    };

    this.editLinkData.subscribe(subscriber);
  }

  private appendHandlerToUnlinkDataButton() {
    const subscriber = new DefaultButtonSubscriber();

    subscriber.update = (message) => {
      if (message.click) {
        (message.click as PointerEvent).preventDefault();
        (message.click as PointerEvent).stopPropagation();
        this.hideDropdownMenu();

        ExcelDataLinkingStore.set({
          loaded: {
            siteId: "",
            driveId: "",
            fileId: "",
            worksheetId: "",
            workbookId: "",
            range: "",
            dataRefreshTimestamp: null,
            autoRefresh: null,
          },
        });

        this.updateButtonsStates();
      }
    };

    this.unlinkData.subscribe(subscriber);
  }

  private appendHandlerToRefreshDataButton() {
    const subscriber = new DefaultButtonSubscriber();

    const loadRangeAndSetData = async (accessToken: string) => {
      const loaded = ExcelDataLinkingStore.get().loaded;
      const { siteId, driveId, fileId, worksheetId, workbookId, range, autoRefresh } = loaded;

      const browser = SharepointBrowserAPI.createInstance(accessToken);

      const response = workbookId
        ? await browser.getTableRange(driveId, fileId, workbookId)
        : await browser.getRange(driveId, fileId, worksheetId, range);

      if (response.message) {
        ExcelDataLinkingStore.set({
          loaded: {
            ...loaded,
            errorMessage: response.message,
          }
        });
        this.updateButtonsStates();
        return;
      }

      const rows = transformSharePointResponse(response.values);

      // set new data refresh timestamp
      ExcelDataLinkingStore.set({
        loaded: {
          ...loaded,
          dataRefreshTimestamp: new Date(),
        }
      });

      dataLinkingEventBus.dispatch("range-selected", rows);
    };

    subscriber.update = async (message) => {
      if (message.click) {
        (message.click as PointerEvent).preventDefault();

        if (ExcelDataLinkingStore.get().accessToken) {
          await loadRangeAndSetData(ExcelDataLinkingStore.get().accessToken);
          return;
        }

        await signInService.authenticateWithFilePermissions(licensing.getCurrentUser()?.organizationId);
        await loadRangeAndSetData(signInService.getSharepointToken());
      }
    };

    this.refreshData.buttonNode().style.alignSelf = "center";

    this.refreshData.subscribe(subscriber);
  }

  private appendHandlerToSplitButton() {
    const subscriber = new SplitButtonSubscriber();

    subscriber.update = async (message: SplitButtonData, action: SplitButtonActions) => {
      if (message.click.type !== "click") {
        return;
      }

      // directly open the link data modal
      if (action === SplitButtonActions.ButtonClick) {
        this.hideDropdownMenu();

        await signInService.authenticateWithFilePermissions(licensing.getCurrentUser()?.organizationId);
        mountReactExcelDataLinking(signInService.getSharepointToken());
        return;
      }

      if (action === SplitButtonActions.IconClick) {
        this.showDropdownMenu();
      }
    };

    this.splitButton.subscribe(subscriber);
  }

  private appendHandlerToAutoRefreshDataToggle() {
    const subscriber = new ToggleButtonSubscriber();
    subscriber.update = (message: ToggleButtonData): void => {

      const loaded = ExcelDataLinkingStore.get().loaded;
      // set new auto refresh value
      ExcelDataLinkingStore.set({
        loaded: {
          ...loaded,
          autoRefresh: message.value,
        }
      });

      this.updateButtonsStates();
    };

    this.autoRefreshDataToggle.subscribe(subscriber);
  }

  private replaceTextWithIconOnSplitButton() {
    this.splitButton.buttonNode().style.alignSelf = "center";

    const splitButtonTextNode: HTMLElement = this.splitButton.defaultButton.buttonNode();

    // hack to remove the text from the split button and replace it with an icon
    while (splitButtonTextNode.firstChild) {
      this.splitButton.defaultButton.buttonNode().removeChild(splitButtonTextNode.firstChild);
    }

    const icon = new IconButton(IconName.Link, false);

    icon.prependTo(splitButtonTextNode);
  }

  private hideDropdownMenu(event?: PointerEvent) {
    event?.stopPropagation();

    this.buttonsDropdownMenu.classList.add("hidden");

    this.clickOutsideOverlayElement.classList.add("hidden");
    this.clickOutsideOverlayElement.removeEventListener("click", this.hideDropdownMenu.bind(this));
  }

  private showDropdownMenu() {
    const { right, bottom } = this.splitButton.buttonNode().getBoundingClientRect();
    const rightOffset = Math.round(document.body.getBoundingClientRect().width - right);

    this.buttonsDropdownMenu.style.top = `${bottom}px`;
    this.buttonsDropdownMenu.style.right = `${rightOffset}px`;
    this.buttonsDropdownMenu.classList.remove("hidden");

    this.clickOutsideOverlayElement.classList.remove("hidden");
    this.clickOutsideOverlayElement.addEventListener("click", this.hideDropdownMenu.bind(this));
  }
}
