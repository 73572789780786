import { ToolbarOptions } from "@zebrabi/global-toolbar-old/interface/ToolbarOption";
import BaseSwitcherWithHeaderOld from "./BaseSwitcherWithHeaderOld";
import * as d3 from "../../d3";
import { DIV, P, CLICK, BLACK } from "../../library/constants";
import { ChartSettings } from "../../settings/chartSettings";
import { input } from "@zebrabi/global-toolbar-old/components/form/input";
import { InputOption } from "@zebrabi/global-toolbar-old/components/global-toolbar/InputConfig";
import { select } from "@zebrabi/global-toolbar-old/components/form/select";
import { ORGANIZATION_STYLE_PREFIX, OrganizationStyleSettings } from "@zebrabi/data-helpers/organizationStyles";
import { licensing } from "@zebrabi/licensing/Licensing";
import { createExportImportSettingsDialog } from "@zebrabi/data-helpers/exportImportSettings";
import { createInvalidLicenseDialog } from "@zebrabi/data-helpers/invalidLicenseDialog";
import Pickr from "@simonwep/pickr";
import { DataLabelsUnits } from "@zebrabi/zebrabi-core";
import { CategoryDisplayOptions, CategoryWidthOptions, ChartStyle, DataLabelUnitOptions, VarianceDisplayType } from "@zebrabi/constants";
import { ChartType, MarkerSize } from "@zebrabi/charts";
import { ABSOLUTE, ABSOLUTE_RELATIVE, ACTUAL, ACTUAL_ABSOLUTE, ACTUAL_RELATIVE, INTEGRATED, RELATIVE, RESPONSIVE, WATERFALL } from "@zebrabi/charts/dist/lib/consts";

export default class SettingsChartsSwitcher extends BaseSwitcherWithHeaderOld {
    static readonly CLASS_NAME = `SettingsChartsSwitcher`;

    toolbarOptions: ToolbarOptions = {
        collapsed: true,
        elementName: "Settings",
        icon: "settings-icon-base64",
        type: "button",
        actions: [],
    };
    public getClassName(): string {
        return SettingsChartsSwitcher.CLASS_NAME;
    }
    buttonAction(action: string, message: string): void {
    }

    update(message: Map<string, any>): void {}

    createMenuItems(switcherMenuContainer: HTMLElement, active = "") {
        super.createMenuItems(switcherMenuContainer, active);
        const fieldsForm = d3.select(switcherMenuContainer.querySelector(".body")).append(DIV).classed("fields-settings-form-2", true);
        const innerForm = fieldsForm.append(DIV).classed("inner-form", true);
        this.createSettingsForm(innerForm);
        this.createImportExportSettingsUI(fieldsForm, innerForm);
        this.observeFormChanges(fieldsForm.node());
    }

    // eslint-disable-next-line max-lines-per-function
    private createSettingsForm(innerForm: d3.Selection<HTMLDivElement, any, any, any>) {
        const settings: ChartSettings = this.getVisual().settings;

        if (!settings) {
            return;
        }

        // invert variance colors
        innerForm.node().append(input({
            checked: settings.invert,
            id: "invert-variance-colors-id",
            label: "Invert variance colors",
            name: "invert",
            type: "checkbox",
            value: "true"
        }));

        const validLayoutValues: Map<string, InputOption> = this.getValidChartLayouts();
        innerForm.node().append(select({
            id: "layout-select",
            label: "Layout",
            name: "chartLayout",
            options: Array.from(validLayoutValues.values()),
            value: settings.chartLayout && Array.from(validLayoutValues.values()).some(p => p.value === settings.chartLayout) ? settings.chartLayout : RESPONSIVE
        }));

        if (settings.chartTypeSupportsVertical(settings.chartType)) {
            const verticalAxisChkBoxDiv = innerForm.append(DIV);
            const verticalAxischeckbox = verticalAxisChkBoxDiv.append("input").classed("vertical-axis", true).attr("id", "vertical-axis-id").node();
            verticalAxischeckbox.type = "checkbox";
            verticalAxischeckbox.value = "false";
            verticalAxischeckbox.name = "showVerticalCharts";
            verticalAxischeckbox.checked = settings.showVerticalCharts;
            const verticalAxisLabel = verticalAxisChkBoxDiv.append("label").node();
            verticalAxisLabel.textContent = "Show vertical axis";
            verticalAxisLabel.htmlFor = "vertical-axis-id";
        }

        innerForm.node().append(input({
            checked: settings.showGrandTotal,
            id: "show-grand-total-id",
            label: "Grand total",
            name: "showGrandTotal",
            type: "checkbox",
            value: "true"
        }));

        const chkBoxDiffHighlightDiv = innerForm.append(DIV);
        const checkboxDiffHighlight = chkBoxDiffHighlightDiv.append("input").classed("diff-highlight", true).attr("id", "diff-highlight-id").node();
        checkboxDiffHighlight.type = "checkbox";
        checkboxDiffHighlight.value = "true";
        checkboxDiffHighlight.checked = settings.differenceHighlight !== false;
        checkboxDiffHighlight.name = "differenceHighlight";
        checkboxDiffHighlight.style.display = "inline";
        checkboxDiffHighlight.style.width = "20px";
        checkboxDiffHighlight.style.height = "17px";
        checkboxDiffHighlight.style.verticalAlign = "bottom";
        checkboxDiffHighlight.style.marginTop = "6px";
        const diffHighlightLabel = chkBoxDiffHighlightDiv.append("label").node();
        diffHighlightLabel.textContent = "Show difference highlight";
        diffHighlightLabel.htmlFor = "diff-highlight-id";

        if (settings.displayUnits !== DataLabelsUnits.Auto && settings.displayUnits !== DataLabelsUnits.None) {
            innerForm.node().append(select({
                id: "show-units-id",
                label: "Show units in",
                name: "showUnits",
                options: [
                    <InputOption>{ title: "None", value: DataLabelUnitOptions.None, default: true },
                    <InputOption>{ title: "Title", value: DataLabelUnitOptions.Title },
                    <InputOption>{ title: "Data labels", value: DataLabelUnitOptions.DataLabels },
                ],
                type: "number",
                value: `${settings.showUnits}`
            }));
        }

        const showVerticalCharts = settings?.shouldPlotVerticalCharts();
        if (showVerticalCharts) {
            // category width
            innerForm.node().append(select({
                id: "category-width-id",
                label: "Category width",
                name: "verticalCategoriesDisplay",
                options: [
                    <InputOption>{ title: "Auto", value: CategoryDisplayOptions.Auto, default: true },
                    <InputOption>{ title: "Full", value: CategoryDisplayOptions.Full },
                    <InputOption>{ title: "Fixed", value: CategoryDisplayOptions.FixedWidth },
                ],
                type: "number",
                value: `${settings.verticalCategoriesDisplay}` || `${CategoryDisplayOptions.Auto}`
            }));
        }
        else if (this.getVisual().viewModel?.isMultiples) {
            // category width
            innerForm.node().append(select({
                id: "category-width-id",
                label: "Category width",
                name: "categoryWidth",
                options: [
                    <InputOption>{ title: "Auto", value: CategoryWidthOptions.Auto, default: true },
                    <InputOption>{ title: "Fixed", value: CategoryWidthOptions.Fixed },
                ],
                type: "number",
                value: `${settings.categoryWidth}` || `${CategoryWidthOptions.Auto}`
            }));
        }

        // category min width
        const categoryMinWidthDiv = innerForm.append(DIV);
        categoryMinWidthDiv.classed("hidden", showVerticalCharts ? settings?.verticalCategoriesDisplay !== CategoryDisplayOptions.FixedWidth :
            this.getVisual().viewModel?.chartData.length === 1 || settings?.categoryWidth !== CategoryWidthOptions.Fixed);
        const categoryMinWidthLabel = categoryMinWidthDiv.append("label").node();
        categoryMinWidthLabel.textContent = "Min width";
        categoryMinWidthLabel.htmlFor = "category-min-width-id";
        const categoryMinWidthInput = categoryMinWidthDiv.append("input").classed("category-min-width", true).attr("id", "category-min-width-id");
        const categoryMinWidthInputEl = categoryMinWidthInput.node();
        categoryMinWidthInputEl.type = "number";
        categoryMinWidthInputEl.min = "0";
        categoryMinWidthInputEl.max = "10000";
        categoryMinWidthInputEl.value = `${settings.categoryMinWidth}` || "35";
        categoryMinWidthInputEl.name = "categoryMinWidth";

        innerForm.append(P).text("Design").classed("settings", true);

        // style
        let orgStyleInputOptions: InputOption[] = [];
        if (this.getVisual().getInstance().availableOrganizationStyles) {
            orgStyleInputOptions = this.getVisual().getInstance().availableOrganizationStyles.map(styleData => {
                return <InputOption>{ title: styleData.name, value: ORGANIZATION_STYLE_PREFIX + styleData.id };
            });
        }
        let designSettingsValue = settings && settings.chartStyle ? settings.chartStyle.toString() : "0";
        if (settings && settings.chartStyle === ChartStyle.Company) {
            designSettingsValue = ORGANIZATION_STYLE_PREFIX + settings.selectedOrganizationStyleId;
        }

        innerForm.node().append(select({
            id: "style-selector",
            label: "Style",
            name: "chartStyle",
            type: "string",
            value: designSettingsValue,
            options: [
                <InputOption>{ title: "Zebra", value: `${ChartStyle.Zebra}`, default: true },
                <InputOption>{ title: "Zebra Light", value: `${ChartStyle.ZebraLight}` },
                <InputOption>{ title: "Dr. Hichert", value: `${ChartStyle.DrHichert}` },
                <InputOption>{ title: "Power BI", value: `${ChartStyle.PowerBI}` },
                <InputOption>{ title: "Colorblind-friendly", value: `${ChartStyle.ColorblindSafe}` },
                <InputOption>{ title: "Custom", value: `${ChartStyle.Custom}` },
                ...orgStyleInputOptions
            ]
        }));

        const styleCustomDiv = innerForm.append(DIV);
        styleCustomDiv.classed("styleCustom");
        styleCustomDiv.classed("hidden", settings.chartStyle !== ChartStyle.Custom);

        const mainColorsSettings = [
            { name: `Positive`, settingsProperty: `positiveColor` },
            { name: `Negative`, settingsProperty: `negativeColor` },
            { name: `Neutral`, settingsProperty: `neutralColor` },
            { name: `Markers`, settingsProperty: `markerColor` },
            { name: `Lines`, settingsProperty: `lineColor` },
            { name: `Axis`, settingsProperty: `axisColor` },
            { name: `DotChart`, settingsProperty: `dotChartColor` },
            { name: `Highlight`, settingsProperty: `highlightColor` }];


        this.populateColorPickers(styleCustomDiv, settings, mainColorsSettings);

        // more colors
        const chkBoxMoreColorsDiv = innerForm.append(DIV);
        chkBoxMoreColorsDiv.classed("settings", true)
            .classed("hidden", settings?.chartStyle !== ChartStyle.Custom);
        const chkBoxCustomColors = chkBoxMoreColorsDiv.append("input")
            .classed("use-more-colors", true)
            .attr("id", "use-more-colors-id").node();
        chkBoxCustomColors.type = "checkbox";
        chkBoxCustomColors.value = "true";
        chkBoxCustomColors.checked = settings?.colorScheme.useCustomScenarioColors;
        chkBoxCustomColors.name = "colorScheme.useCustomScenarioColors";
        const chkBoxCustomColorsLabel = chkBoxMoreColorsDiv.append("label").node();
        chkBoxCustomColorsLabel.textContent = "Use more colors";
        chkBoxCustomColorsLabel.htmlFor = "use-more-colors-id";
        (<HTMLInputElement>chkBoxCustomColors).onchange = (ev: Event) => {
            moreColorsDiv.classed("hidden", !(<HTMLInputElement>ev.target).checked);
        };

        const moreColorsSettings = [
            { name: `Plan`, settingsProperty: `planColor` },
            { name: `Previous Year`, settingsProperty: `previousYearColor` },
            { name: `Forecast`, settingsProperty: `forecastColor` }];

        const moreColorsDiv = innerForm.append(DIV);
        moreColorsDiv.classed("hidden", !settings?.colorScheme.useCustomScenarioColors);
        this.populateColorPickers(moreColorsDiv, settings, moreColorsSettings);

        // custom design settings
        const chkBoxApplyPatternsDiv = innerForm.append(DIV);
        chkBoxApplyPatternsDiv.classed("settings", true)
            .classed("hidden", settings?.chartStyle !== ChartStyle.Custom);
        const chkBoxApplyPatterns = chkBoxApplyPatternsDiv.append("input")
            .classed("apply-patterns", true)
            .attr("id", "apply-patterns-id").node();
        chkBoxApplyPatterns.type = "checkbox";
        chkBoxApplyPatterns.value = "true";
        chkBoxApplyPatterns.name = "colorScheme.applyPatterns";
        chkBoxApplyPatterns.checked = settings.colorScheme.applyPatterns;
        const chkBoxApplyPatternsLabel = chkBoxApplyPatternsDiv.append("label").node();
        chkBoxApplyPatternsLabel.textContent = "Apply patterns";
        chkBoxApplyPatternsLabel.htmlFor = "apply-patterns-id";

        // area neutral opacity
        if (settings.chartType === ChartType.Area) {
            innerForm.append(P).text("Area Neutral Opacity").classed("settings", true);
            const areaNeutralOpacity = innerForm.append("input").classed("area-neutral-opacity", true).node();
            areaNeutralOpacity.type = "number";
            areaNeutralOpacity.min = "0";
            areaNeutralOpacity.max = "100";
            areaNeutralOpacity.value = settings.areaNeutralOpacity.toString() || "100";
            areaNeutralOpacity.name = "areaNeutralOpacity";

            // area variance opacity
            innerForm.append(P).text("Area Variance Opacity").classed("settings", true);
            const areaVarianceOpacity = innerForm.append("input").classed("area-variance-opacity", true).node();
            areaVarianceOpacity.type = "number";
            areaVarianceOpacity.min = "0";
            areaVarianceOpacity.max = "100";
            areaVarianceOpacity.value = settings.areaVarianceOpacity.toString() || "100";
            areaVarianceOpacity.name = "areaVarianceOpacity";
        }
        // variance display type
        if (settings.chartType === ChartType.Variance) {
            if (settings.chartLayout === RESPONSIVE && settings.valueChartIntegrated || settings.chartLayout === INTEGRATED) {
                innerForm.node().append(select({
                    id: "variance-selector",
                    label: "Variance display type",
                    name: "varianceDisplayType",
                    type: "number",
                    value: `${settings.varianceDisplayType}`,
                    options: [
                        <InputOption>{ title: "Bar", value: VarianceDisplayType.Bar, default: true },
                        <InputOption>{ title: "Arrow", value: VarianceDisplayType.Chevron },
                    ]
                }));
            }
            // reference marker size
            innerForm.node().append(select({
                id: "reference-marker-size",
                label: "Reference marker size",
                name: "referenceMarkerSize",
                options: [
                    <InputOption>{ title: "Auto", value: MarkerSize.Auto, default: true },
                    <InputOption>{ title: "Fixed", value: MarkerSize.Fixed }
                ],
                type: "number",
                value: `${settings.referenceMarkerSize}`
            }));

            // reference marker fixed size
            innerForm.append(P).text("Reference marker fixed size").classed("settings", true);
            const referenceMarkerFixedSize = innerForm.append("input").classed("reference-marker-fixed-size", true).node();
            referenceMarkerFixedSize.type = "number";
            referenceMarkerFixedSize.min = "8";
            referenceMarkerFixedSize.max = "30";
            referenceMarkerFixedSize.value = `${settings.referenceMarkerFixedSize}`;
            referenceMarkerFixedSize.name = "referenceMarkerFixedSize";
        }

        // lighten overlapped
        const chkBoxLightenOverlappedDiv = innerForm.append(DIV);
        chkBoxLightenOverlappedDiv.classed("settings", true);
        const chkBoxLightenOverlappedEl = chkBoxLightenOverlappedDiv.append("input").classed("lighten-overlapped", true).attr("id", "lighten-overlapped-id").node();
        chkBoxLightenOverlappedEl.type = "checkbox";
        chkBoxLightenOverlappedEl.value = "true";
        chkBoxLightenOverlappedEl.checked = settings.lightenOverlapped;
        chkBoxLightenOverlappedEl.name = "lightenOverlapped";
        const chkBoxLightenOverlappedLabel = chkBoxLightenOverlappedDiv.append("label").node();
        chkBoxLightenOverlappedLabel.textContent = "Lighten overlapped";
        chkBoxLightenOverlappedLabel.htmlFor = "lighten-overlapped-id";

        this.observeFormChanges(innerForm.node());
    }

    public updateStyle(updatedStyle) {
        // Visual.getInstance().setOrganizationStyleSettings(Visual.settings, updatedStyle);
        // Visual.settings.persist(true);
        // Visual.getInstance().update(Visual.settings);
    }

    private createImportExportSettingsUI(fieldsForm: d3.Selection<HTMLElement, any, any, any>, innerForm: d3.Selection<HTMLDivElement, any, any, any>) {
        const importExportSettingsDiv = document.createElement("div");
        fieldsForm.node().insertBefore(importExportSettingsDiv, innerForm.node());
        importExportSettingsDiv.classList.add("edit-source-data");
        const importExportSettingsDivSelection = d3.select(importExportSettingsDiv);

        const editDataSourceDiv = importExportSettingsDivSelection.append(DIV);

        const importExportSettingsBtn = editDataSourceDiv.append("button")
            .classed("save-theme-btn", true)
            .text("Save theme");

        const dialogConfirmHandler = (updatedStyle: any) => {
            this.updateStyle(updatedStyle);
        };

        importExportSettingsBtn.on(CLICK, (event: MouseEvent) => {
            if (event?.detail > 1) {    // prevent double click
                return;
            }

            // Check for valid license
            if (licensing.getCurrentUser()?.getLicense()?.hasLicense) {
                createExportImportSettingsDialog<OrganizationStyleSettings>(this.getExportableSettings(this.getVisual().settings), dialogConfirmHandler);
            } else {
                createInvalidLicenseDialog(licensing.getCurrentUser()?.isTrial());
            }
        });
    }


    getValidChartLayouts(): Map<string, InputOption> {
        let validChartLayoutValues: Map<string, InputOption>;
        const settings = this.getVisual().settings;

        if (settings.showVerticalCharts) {
            validChartLayoutValues = settings.chartType === ChartType.Variance ?
                new Map<string, InputOption>([
                    [INTEGRATED, {
                        title: `${INTEGRATED}`,
                        value: INTEGRATED
                    }],
                    [ACTUAL, {
                        title: `${ACTUAL}`,
                        value: ACTUAL
                    }]
                ]) :
                new Map<string, InputOption>([
                    [WATERFALL, {
                        title: `${WATERFALL}`,
                        value: WATERFALL
                    }]
                ]);
        }
        else {
            if (settings.chartType === ChartType.Area) {
                validChartLayoutValues = new Map<string, InputOption>([
                    [INTEGRATED, {
                        title: `${INTEGRATED}`,
                        value: INTEGRATED
                    }],
                    [ACTUAL, {
                        title: `${ACTUAL}`,
                        value: ACTUAL
                    }]
                ]);
            }
            else if (settings.chartType === ChartType.Waterfall) {
                validChartLayoutValues = new Map<string, InputOption>([
                    [RESPONSIVE, {
                        title: `${RESPONSIVE}`,
                        value: RESPONSIVE
                    }],
                    [WATERFALL, {
                        title: `${WATERFALL}`,
                        value: WATERFALL
                    }]
                ]);
            }
            else {
                validChartLayoutValues = new Map<string, InputOption>([
                    [RESPONSIVE, {
                        title: `${RESPONSIVE}`,
                        value: RESPONSIVE
                    }],
                    [INTEGRATED, {
                        title: `${INTEGRATED}`,
                        value: INTEGRATED
                    }],
                    [ABSOLUTE, {
                        title: `${ABSOLUTE}`,
                        value: ABSOLUTE
                    }],
                    [RELATIVE, {
                        title: `${RELATIVE}`,
                        value: RELATIVE
                    }],
                    [ABSOLUTE_RELATIVE, {
                        title: `${ABSOLUTE_RELATIVE}`,
                        value: ABSOLUTE_RELATIVE
                    }],
                    [ACTUAL_ABSOLUTE, {
                        title: `${ACTUAL_ABSOLUTE}`,
                        value: ACTUAL_ABSOLUTE
                    }],
                    [ACTUAL_RELATIVE, {
                        title: `${ACTUAL_RELATIVE}`,
                        value: ACTUAL_RELATIVE
                    }],
                    [ACTUAL, {
                        title: `${ACTUAL}`,
                        value: ACTUAL
                    }]
                ]);
            }
        }

        if (validChartLayoutValues.size < 2 || settings.stackedChart) {
            validChartLayoutValues = new Map<string, InputOption>([[RESPONSIVE, {
                title: `${RESPONSIVE}`,
                value: RESPONSIVE
            }]]);
        }

        return validChartLayoutValues;
    }

    private getExportableSettings(settings: ChartSettings): OrganizationStyleSettings {
        return {
            colorScheme: settings.colorScheme,

            labelFontFamily: settings.labelFontFamily,
            labelFontSize: settings.labelFontSize,
            labelFontColor: settings.labelFontColor,

            titleFontFamily: settings.titleFontFamily,
            titleFontSize: settings.titleFontSize,
            titleFontColor: settings.titleFontColor,

            groupTitleFontFamily: settings.groupTitleFontFamily,
            groupTitleFontSize: settings.groupTitleFontSize,
            groupTitleFontColor: settings.groupTitleFontColor,

            varianceDisplayType: settings.varianceDisplayType,

            previousYear: settings.previousYear,
            actual: settings.actual,
            plan: settings.plan,
            forecast: settings.forecast,

            actual_previousYear: settings.actual_previousYear,
            actual_previousYear_percent: settings.actual_previousYear_percent,
            actual_plan: settings.actual_plan,
            actual_plan_percent: settings.actual_plan_percent,
            actual_forecast: settings.actual_forecast,
            actual_forecast_percent: settings.actual_forecast_percent,

            forecast_previousYear: settings.forecast_previousYear,
            forecast_previousYear_percent: settings.forecast_previousYear_percent,
            forecast_plan: settings.forecast_plan,
            forecast_plan_percent: settings.forecast_plan_percent,

            previousYear_plan: settings.previousYear_plan,
            previousYear_plan_percent: settings.previousYear_plan_percent,

        };
    }

    private populateColorPickers(divContainer: d3.Selection<HTMLDivElement, any, any, any>, settings: ChartSettings, colorSettings: any) {
        colorSettings.forEach(colorSetting => {
            const colorID = colorSetting.name.replace(` `, ``);
            const color = settings?.colorScheme[colorSetting.settingsProperty] || BLACK;

            divContainer.append(P).text(colorSetting.name);
            const colorContainer = divContainer.append(DIV);
            colorContainer.classed("customColorContainer", true);

            const customColorBoxDiv = colorContainer.append(DIV);
            customColorBoxDiv.attr("id", `${colorID}ColorPicker`);
            customColorBoxDiv.classed("color", true);
            customColorBoxDiv.style("background-color", color);

            customColorBoxDiv.node().append(input({
                hidden: true,
                id: `${colorID}ColorPickerInput`,
                label: "",
                name: `colorScheme.${colorSetting.settingsProperty}`,
                type: "hidden",
                value: color
            }));

            const arrowEl = document.createElement(DIV);
            arrowEl.classList.add("arrow");
            colorContainer.node().append(arrowEl);
            colorContainer.on("click", () => {
                this.getCustomColorPicker(`${colorID}ColorPicker`, color);
            });
        });
    }

    public getCustomColorPicker(pickerID: string, currentColor: string) {
        const colorPicker = Pickr.create({
            el: "#" + pickerID,
            container: "#sandbox-host",
            theme: "monolith",
            swatches: null,
            appClass: "fillpickr",
            closeWithKey: "Escape",
            showAlways: false,
            adjustableNumbers: true,
            default: currentColor,
            useAsButton: true,
            autoReposition: false,
            defaultRepresentation: "RGBA",
            components: {
                preview: true,
                hue: true,
                opacity: false,
                interaction: {
                    save: true,
                    clear: true,
                    input: true,
                },
            },
        });
        const colorEl = d3.select(`#${pickerID}`);
        const colorInput: HTMLInputElement = <HTMLInputElement>document.getElementById(`${pickerID}Input`);

        colorPicker.on("clear", () => {
            colorEl.style(`background-color`, currentColor);
        }).on("change", (color) => {
            const hexColor = color ? color.toHEXA().toString() : null;
            colorEl.style(`background-color`, hexColor);
            colorInput.value = hexColor;
        }).on("save", () => {
            colorPicker.hide();
            colorInput.dispatchEvent(new Event("change"));
        });

        colorPicker.show();
        // preventing global toolbar to close
        (<any>colorPicker.getRoot()).app.addEventListener(CLICK, (e) => {
            e.stopImmediatePropagation();
        });

        return colorPicker;
    }
}
