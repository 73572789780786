import { Observable } from "@zebrabi/observable";
import { getOfficeSettings, setOfficeSettings } from "@zebrabi/office-settings";
import { SharePointSite, SharePointDrive, SharePointFolder, SharePointFile } from "./ExcelDataLinking.types";
import { User } from "@zebrabi/licensing/interfaces/User";

export const EXCEL_DATA_LINKING_STORE = "ExcelDataLinkingStore";

type StoreData = {
  currentView: "file-selection" | "data-selection";
  sites: SharePointSite[];
  drives: SharePointDrive[];
  onedrives: SharePointDrive[];
  foldersNavigation: SharePointFolder[];
  foldersAndFiles: (SharePointFolder | SharePointFile)[];
  activeSiteId: string;
  activeDriveId: string;
  activeWorksheetId: string;
  activeWorkbookId: string;
  activeFileId: string;
  activeFile: SharePointFile;
  accessToken: string;
  showFullScreenLoader: boolean;
  loaded: {
    siteId: string;
    driveId: string;
    worksheetId: string;
    workbookId: string; // excel table id??
    fileId: string;
    range: string;
    dataRefreshTimestamp: Date;
    autoRefresh: boolean;
    errorMessage?: string;
    linkCreator?: User
    linkSourceDataFile?: SharePointFile
  };
};

class Store {
  static data;

  static initialData: StoreData = {
    currentView: "file-selection",
    sites: [],
    drives: [],
    onedrives: [],
    foldersNavigation: [],
    foldersAndFiles: [],
    activeSiteId: "",
    activeDriveId: "",
    activeWorksheetId: "",
    activeWorkbookId: "",
    activeFileId: "",
    activeFile: null,
    accessToken: "",
    showFullScreenLoader: false,
    loaded: {
      siteId: "",
      driveId: "",
      worksheetId: "",
      workbookId: "",
      fileId: "",
      range: "",
      dataRefreshTimestamp: null,
      autoRefresh: false, //todo: check if it should be true by default
    },
  };

  constructor() {
    const officeData = getOfficeSettings(EXCEL_DATA_LINKING_STORE);

    if (officeData) {
      Store.initialData.loaded = officeData;
    }
    Store.data = new Observable<StoreData>(Store.initialData);
  }

  static get(): StoreData {
    return Store.data.get();
  }

  static set(value: Partial<StoreData>) {
    const nextValue = {
      ...Store.data.get(),
      ...value,
    };

    Store.data.set(nextValue);

    if (Object.keys(value).includes("loaded")) {
      setOfficeSettings(EXCEL_DATA_LINKING_STORE, Store.data.get()["loaded"]);
    }
  }

  static subscribe(handler: (value: StoreData) => void) {
    Store.data.subscribe(handler);
  }

  static unsubscribe() {
    Store.data.unsubscribe();
  }
}

export type { StoreData as ExcelDataLinkingStoreData };

export { Store as ExcelDataLinkingStore };
